export const dataCalendar = [{
    "value": 103,
    "day": "2023-04-15"
},
{
    "value": 377,
    "day": "2023-09-01"
},
{
    "value": 244,
    "day": "2023-11-17"
},
{
    "value": 36,
    "day": "2023-07-29"
},
{
    "value": 15,
    "day": "2023-07-10"
},
{
    "value": 67,
    "day": "2023-10-10"
},
{
    "value": 115,
    "day": "2023-05-31"
},
{
    "value": 217,
    "day": "2023-11-26"
},
{
    "value": 134,
    "day": "2023-03-01"
},
{
    "value": 140,
    "day": "2023-05-09"
},
{
    "value": 365,
    "day": "2023-03-27"
},
{
    "value": 181,
    "day": "2023-08-17"
},
{
    "value": 212,
    "day": "2023-10-22"
},
{
    "value": 236,
    "day": "2023-02-18"
},
{
    "value": 284,
    "day": "2023-03-15"
},
{
    "value": 304,
    "day": "2023-03-11"
},
{
    "value": 53,
    "day": "2018-01-19"
},
{
    "value": 281,
    "day": "2023-09-05"
},
{
    "value": 145,
    "day": "2023-11-25"
},
{
    "value": 217,
    "day": "2023-08-22"
},
{
    "value": 165,
    "day": "2023-03-25"
},
{
    "value": 377,
    "day": "2018-02-08"
},
{
    "value": 208,
    "day": "2023-08-19"
},
{
    "value": 391,
    "day": "2023-06-23"
},
{
    "value": 283,
    "day": "2023-04-06"
},
{
    "value": 31,
    "day": "2023-10-02"
},
{
    "value": 300,
    "day": "2018-04-24"
},
{
    "value": 91,
    "day": "2023-09-10"
},
{
    "value": 172,
    "day": "2023-05-19"
},
{
    "value": 327,
    "day": "2023-12-25"
},
{
    "value": 61,
    "day": "2018-06-12"
},
{
    "value": 363,
    "day": "2023-12-02"
},
{
    "value": 247,
    "day": "2023-07-10"
},
{
    "value": 369,
    "day": "2023-10-24"
},
{
    "value": 362,
    "day": "2023-09-11"
},
{
    "value": 275,
    "day": "2023-10-23"
},
{
    "value": 216,
    "day": "2023-11-05"
},
{
    "value": 20,
    "day": "2023-06-27"
},
{
    "value": 47,
    "day": "2023-11-11"
},
{
    "value": 204,
    "day": "2023-09-22"
},
{
    "value": 27,
    "day": "2018-02-01"
},
{
    "value": 228,
    "day": "2023-10-24"
},
{
    "value": 183,
    "day": "2018-07-27"
},
{
    "value": 378,
    "day": "2023-07-28"
},
{
    "value": 231,
    "day": "2023-05-20"
},
{
    "value": 36,
    "day": "2023-07-31"
},
{
    "value": 84,
    "day": "2023-06-25"
},
{
    "value": 321,
    "day": "2023-06-17"
},
{
    "value": 226,
    "day": "2023-07-27"
},
{
    "value": 322,
    "day": "2023-10-13"
},
{
    "value": 247,
    "day": "2023-02-22"
},
{
    "value": 55,
    "day": "2023-12-07"
},
{
    "value": 303,
    "day": "2023-12-27"
},
{
    "value": 20,
    "day": "2023-10-18"
},
{
    "value": 186,
    "day": "2023-07-02"
},
{
    "value": 196,
    "day": "2023-01-14"
},
{
    "value": 360,
    "day": "2018-04-07"
},
{
    "value": 90,
    "day": "2023-10-09"
},
{
    "value": 296,
    "day": "2018-02-05"
},
{
    "value": 38,
    "day": "2023-08-18"
},
{
    "value": 259,
    "day": "2023-04-29"
},
{
    "value": 250,
    "day": "2018-06-14"
},
{
    "value": 104,
    "day": "2023-12-10"
},
{
    "value": 205,
    "day": "2023-06-06"
},
{
    "value": 349,
    "day": "2023-07-20"
},
{
    "value": 50,
    "day": "2023-07-01"
},
{
    "value": 224,
    "day": "2023-10-27"
},
{
    "value": 364,
    "day": "2023-02-10"
},
{
    "value": 281,
    "day": "2018-06-04"
},
{
    "value": 148,
    "day": "2023-09-14"
},
{
    "value": 3,
    "day": "2023-04-25"
},
{
    "value": 182,
    "day": "2023-05-21"
},
{
    "value": 398,
    "day": "2023-09-07"
},
{
    "value": 227,
    "day": "2023-09-08"
},
{
    "value": 129,
    "day": "2023-11-25"
},
{
    "value": 168,
    "day": "2023-09-11"
},
{
    "value": 262,
    "day": "2023-01-09"
},
{
    "value": 208,
    "day": "2023-05-21"
},
{
    "value": 189,
    "day": "2023-02-16"
},
{
    "value": 151,
    "day": "2023-11-16"
},
{
    "value": 47,
    "day": "2023-04-15"
},
{
    "value": 371,
    "day": "2023-12-15"
},
{
    "value": 352,
    "day": "2018-06-13"
},
{
    "value": 170,
    "day": "2023-11-28"
},
{
    "value": 154,
    "day": "2018-07-29"
},
{
    "value": 352,
    "day": "2018-01-14"
},
{
    "value": 254,
    "day": "2023-02-06"
},
{
    "value": 202,
    "day": "2023-10-13"
},
{
    "value": 388,
    "day": "2023-08-24"
},
{
    "value": 33,
    "day": "2018-05-22"
},
{
    "value": 287,
    "day": "2023-12-22"
},
{
    "value": 114,
    "day": "2023-08-22"
},
{
    "value": 88,
    "day": "2023-02-20"
},
{
    "value": 123,
    "day": "2023-08-30"
},
{
    "value": 58,
    "day": "2018-08-07"
},
{
    "value": 185,
    "day": "2018-06-20"
},
{
    "value": 274,
    "day": "2018-04-21"
},
{
    "value": 209,
    "day": "2023-06-11"
},
{
    "value": 303,
    "day": "2018-03-24"
},
{
    "value": 133,
    "day": "2023-12-17"
},
{
    "value": 155,
    "day": "2023-10-18"
},
{
    "value": 332,
    "day": "2018-07-19"
},
{
    "value": 351,
    "day": "2023-12-25"
},
{
    "value": 66,
    "day": "2023-08-17"
},
{
    "value": 379,
    "day": "2023-05-26"
},
{
    "value": 272,
    "day": "2023-08-11"
},
{
    "value": 277,
    "day": "2023-06-24"
},
{
    "value": 324,
    "day": "2018-05-04"
},
{
    "value": 270,
    "day": "2023-10-26"
},
{
    "value": 113,
    "day": "2023-05-09"
},
{
    "value": 219,
    "day": "2023-02-12"
},
{
    "value": 279,
    "day": "2023-05-03"
},
{
    "value": 13,
    "day": "2023-08-07"
},
{
    "value": 25,
    "day": "2018-06-16"
},
{
    "value": 239,
    "day": "2023-07-03"
},
{
    "value": 383,
    "day": "2018-04-14"
},
{
    "value": 250,
    "day": "2023-03-19"
},
{
    "value": 0,
    "day": "2023-10-06"
},
{
    "value": 250,
    "day": "2023-11-01"
},
{
    "value": 298,
    "day": "2023-05-24"
},
{
    "value": 159,
    "day": "2023-07-13"
},
{
    "value": 94,
    "day": "2018-05-21"
},
{
    "value": 117,
    "day": "2018-08-04"
},
{
    "value": 255,
    "day": "2018-04-04"
},
{
    "value": 86,
    "day": "2023-06-26"
},
{
    "value": 96,
    "day": "2018-07-20"
},
{
    "value": 25,
    "day": "2023-07-07"
},
{
    "value": 207,
    "day": "2023-06-22"
},
{
    "value": 308,
    "day": "2023-08-31"
},
{
    "value": 176,
    "day": "2023-05-18"
},
{
    "value": 159,
    "day": "2023-05-16"
},
{
    "value": 369,
    "day": "2023-10-05"
},
{
    "value": 85,
    "day": "2023-04-05"
},
{
    "value": 102,
    "day": "2023-09-16"
},
{
    "value": 62,
    "day": "2018-04-20"
},
{
    "value": 262,
    "day": "2023-04-13"
},
{
    "value": 352,
    "day": "2023-07-22"
},
{
    "value": 25,
    "day": "2023-04-28"
},
{
    "value": 175,
    "day": "2018-05-31"
},
{
    "value": 57,
    "day": "2023-09-16"
},
{
    "value": 170,
    "day": "2023-06-24"
},
{
    "value": 80,
    "day": "2023-11-19"
},
{
    "value": 228,
    "day": "2023-10-26"
},
{
    "value": 291,
    "day": "2023-10-02"
},
{
    "value": 360,
    "day": "2023-07-05"
},
{
    "value": 258,
    "day": "2023-10-09"
},
{
    "value": 295,
    "day": "2023-08-05"
},
{
    "value": 288,
    "day": "2023-10-07"
},
{
    "value": 262,
    "day": "2023-10-08"
},
{
    "value": 248,
    "day": "2023-06-16"
},
{
    "value": 5,
    "day": "2023-07-14"
},
{
    "value": 205,
    "day": "2023-01-20"
},
{
    "value": 20,
    "day": "2023-12-28"
},
{
    "value": 297,
    "day": "2023-05-19"
},
{
    "value": 111,
    "day": "2023-06-04"
},
{
    "value": 261,
    "day": "2023-01-27"
},
{
    "value": 356,
    "day": "2023-03-06"
},
{
    "value": 332,
    "day": "2023-05-26"
},
{
    "value": 34,
    "day": "2023-04-12"
},
{
    "value": 131,
    "day": "2023-07-09"
},
{
    "value": 193,
    "day": "2023-10-21"
},
{
    "value": 76,
    "day": "2023-07-27"
},
{
    "value": 18,
    "day": "2023-12-25"
},
{
    "value": 339,
    "day": "2023-01-12"
},
{
    "value": 135,
    "day": "2023-09-26"
},
{
    "value": 250,
    "day": "2023-09-25"
},
{
    "value": 233,
    "day": "2023-01-29"
},
{
    "value": 130,
    "day": "2023-07-06"
},
{
    "value": 217,
    "day": "2018-01-25"
},
{
    "value": 140,
    "day": "2023-05-02"
},
{
    "value": 123,
    "day": "2023-03-12"
},
{
    "value": 257,
    "day": "2023-04-09"
},
{
    "value": 70,
    "day": "2023-05-29"
},
{
    "value": 347,
    "day": "2023-05-19"
},
{
    "value": 123,
    "day": "2023-05-15"
},
{
    "value": 106,
    "day": "2023-02-21"
},
{
    "value": 46,
    "day": "2023-09-04"
},
{
    "value": 174,
    "day": "2018-05-08"
},
{
    "value": 138,
    "day": "2018-03-28"
},
{
    "value": 236,
    "day": "2023-07-06"
},
{
    "value": 74,
    "day": "2018-08-10"
},
{
    "value": 348,
    "day": "2018-01-28"
},
{
    "value": 52,
    "day": "2018-07-31"
},
{
    "value": 137,
    "day": "2023-12-26"
},
{
    "value": 163,
    "day": "2023-06-07"
},
{
    "value": 291,
    "day": "2023-10-27"
},
{
    "value": 94,
    "day": "2023-11-04"
},
{
    "value": 164,
    "day": "2023-01-31"
},
{
    "value": 221,
    "day": "2023-02-08"
},
{
    "value": 381,
    "day": "2023-08-12"
},
{
    "value": 163,
    "day": "2018-05-11"
},
{
    "value": 77,
    "day": "2023-06-19"
},
{
    "value": 261,
    "day": "2018-01-17"
},
{
    "value": 218,
    "day": "2023-07-02"
},
{
    "value": 389,
    "day": "2023-01-18"
},]

export const dataPieChart = [
    {
        "id": "leader1",
        "label": "lider 1",
        "value": 27,
        "color": "hsl(270, 70%, 50%)"
    },
    {
        "id": "leader2",
        "label": "lider 2",
        "value": 294,
        "color": "hsl(291, 70%, 50%)"
    },
    {
        "id": "leader3",
        "label": "lider 1",
        "value": 374,
        "color": "hsl(275, 70%, 50%)"
    },
    {
        "id": "leader4",
        "label": "lider 4",
        "value": 29,
        "color": "hsl(349, 70%, 50%)"
    },
    {
        "id": "leader5",
        "label": "lider 5",
        "value": 397,
        "color": "#FFD500"
    }
]